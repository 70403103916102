import React from "react";
import {GlobalStyle, MainContainer, CenteredContainer} from "../style";
import Head from "../components/common/head";
import NavBar from "../components/common/navBar";
import FooterBar from "../components/common/footer";

const ContactUs = () => {
  return (
    <MainContainer>
      <GlobalStyle/>
      <Head/>
      <NavBar/>
      <CenteredContainer className="contact-us">
        <h2 className="heading-text">
          <span>Contact Us</span>
        </h2>
        <div className="small-text">
          For speaking opportunities, sponsorships, queries and additional information, please reach out to us at
        </div>

        <div className="flex-container contact-detail-container">
          <div className="flexbox half-width">
            <h3 className="heading">Registered address</h3>
            <p>
              Flat No.704, B Block, Vaishnavi Splendour Apartment No 12, 
              3rd Cross, Poojari Layout, RMV 2nd Stage, Bangalore, Karnataka, 560094
            </p>
          </div>
          <div className="flexbox half-width">
            <h3 className="heading">Email</h3>
            <p>
              <a href="mailto:contact@cpoem.org" rel="noreferrer" target="_blank">contact@cpoem.org</a>
            </p>
          </div>
        </div>
      </CenteredContainer>
      <FooterBar/>
    </MainContainer>
  );
};

export default ContactUs;
